
export const paymentTypes = [
  { id: 'hour', name: 'За час' },
  { id: 'month', name: 'За месяц' },
  { id: 'task', name: 'Разовая' }
]

export const busynessTypes = [
  { id: 'full', name: 'Полная' },
  { id: 'part', name: 'Частичная' }
]
