<template>
  <FilterControl :name="!single ? name : ''">
    <AtomInput
      v-if="search"
      class="filter-search"
      name="Поиск"
      :symbol-left="true"
      v-model.trim="query"
    ><template #symbol><IconSearch /></template></AtomInput>
    <div v-if="!items?.length" class="filter-search__not-found">{{ notFoundText ?? 'Не найдено' }}</div>
    <Expandable
      v-else
      sticky
      :expandable="!single"
      :max-height="212"
      :expand-text="expandText"
      :shrink-text="shrinkText"
    >
      <div class="controls chips">
        <Chip
          v-for="item in items"
          :key="item.id"
          @click="toggle(item.id)"
          :selected="select ? selected === item.id : selected.includes(item.id)"
          :title="item.name ?? item.title"
        ><QueryEntry :content="item.name ?? item.title" :query="query" /></Chip>
      </div>
    </Expandable>
  </FilterControl>
</template>

<script setup>
import { IconSearch } from '@tabler/icons-vue'

const props = defineProps({
  items: Array,
  modelValue: [Array, String, Number],
  single: Boolean,
  fetched: Array,
  search: Boolean,
  select: Boolean,
  name: String,
  expandText: String,
  shrinkText: String,
  notFoundText: String
})
const emit = defineEmits(['update:modelValue'])

const selected = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})

const query = ref('')
const items = computed(() => {
  const arr = props.items.filter(i => (i.name ?? i.title ?? '').toLowerCase().includes(query.value.toLowerCase()))
  if (props.fetched?.length)
    props.fetched.slice().reverse().forEach(id => {
      const i = arr.findIndex(i => i.id === id)
      if (i !== -1) arr.unshift(arr.splice(i, 1)[0])
    })
  return arr
})

const toggle = id => {
  if (props.select) return selected.value = (selected.value !== id ? id : null)

  if (selected.value.includes(id)) selected.value.splice(selected.value.indexOf(id), 1)
  else selected.value.push(id)
}
</script>

<style scoped lang="scss">
.filter-search {
  margin-bottom: 16px;
}

.filter-search__not-found {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 16px;
  color: var(--color-elements-secondary);
}
</style>
